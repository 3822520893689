import { BehaviorSubject } from 'rxjs';

const metricsSubject = new BehaviorSubject(typeof window !== 'undefined' && JSON.parse(localStorage.getItem('metrics')));
const baseUrl = "https://marketsheets.herokuapp.com";

export const metricService = {
    getAll,
    getByAlias,
    metrics: metricsSubject.asObservable(),
    get metricsValue() { return metricsSubject.value }
};

function get(url) {
    const requestOptions = {
        method: 'GET',
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function getAll() {
    return get(`${baseUrl}/metric/`)
    .then(res => {

        let metrics = {};

        res.metrics.forEach(metric => {
            metrics[metric.alias] = metric.full_name;
        });

        typeof window !== 'undefined' && localStorage.setItem('metrics', JSON.stringify(metrics));
        metricsSubject.next(metrics);

        return metrics;
    });
}

function getByAlias(alias) {
    return get(`${baseUrl}/metric/${alias}`);
}