import React, { useState, useEffect, useRef } from 'react';
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faChevronLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { metricService } from '../services/metric.service';

function SearchMetric() {

    const [copySuccess, setCopySuccess] = useState(false);
    const [selectedTicker, setSelectedTicker] = useState('AAPL');
    const [selectedMetric, setSelectedMetric] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState('Period');
    const [selectedYear, setSelectedYear] = useState('Year');
    const [currentAvailableQuarters, setCurrentAvailableQuarters] = useState([]);
    const [metric, setMetric] = useState(null);
    const [periodDropdown, togglePeriodDropdown] = useState(false);
    const [yearDropdown, toggleYearDropdown] = useState(false);
    const [formula, setFormula] = useState('');
    const [loading, setLoading] = useState(true);
    const [metricView, setMetricView] = useState(false);
    const [metricListView, setMetricListView] = useState(false);
    const [search, setSearch] = useState('');
    const [preLoad, setPreLoad] = useState(6);
    const [metrics, setMetrics] = useState({});
    const [historicalYears, setHistoricalYears] = useState([]);

    const YEARS_OF_DATA = 14;

    useEffect(() => {
        const subscription_metric = metricService.metrics.subscribe(x => {
            if (!x) {
                metricService.getAll()
                    .then(m => {
                        setMetrics(m);
                        setLoading(false);
                        setMetricListView(true);
                    });
            } else {
                setMetrics(x);
                setLoading(false);
                setMetricListView(true);
            }
        });
        let current_yr = new Date().getFullYear();
        let current_mth = new Date().getMonth();
        let all_years = [];

        for (let i = current_yr; i > (current_yr - YEARS_OF_DATA); i--) {
            all_years.push(i)
        }

        if (current_mth < 1) {
            setCurrentAvailableQuarters([]);
        } else if (current_mth < 4) {
            setCurrentAvailableQuarters(['q1']);
        } else if (current_mth < 7) {
            setCurrentAvailableQuarters(['q1', 'q2']);
        } else if (current_mth < 10) {
            setCurrentAvailableQuarters(['q1', 'q2', 'q3']);
        } else {
            setCurrentAvailableQuarters(['q1', 'q2', 'q3', 'q4']);
        }

        setHistoricalYears(all_years);

        // const subscription_user = accountService.user.subscribe(x => setUser(x)); return subscription_user.unsubscribe;
        return subscription_metric.unsubscribe();
    }, []);

    if (!metrics) return null;

    const handleChange = e => {
        setSearch(e.target.value)
        setMetricView(false);
        setMetricListView(true);
        setPreLoad(6);

        let metric = search.trim().toLowerCase();

        if (search.length > 0) {
            let localstorage_metric = JSON.parse(typeof window !== 'undefined' && localStorage.getItem('metrics')); // because I don't want to edit all metrics
            let original = {};

            if (localstorage_metric)
                original = localstorage_metric;

            let filtered = {};
            Object.keys(original).map((alias, index) => {
                let full_name = original[alias]
                if (full_name.toLowerCase().match(metric)) {
                    filtered[alias] = original[alias];
                }
            })
            setMetrics(filtered)
        } else if (search.length == 0) {
            let localstorage_metric = JSON.parse(typeof window !== 'undefined' && localStorage.getItem('metrics'));
            if (localstorage_metric)
                setMetrics(localstorage_metric);
            setPreLoad(6);
        }

    }

    const handleSearch = e => {

    };

    const textToCopy = alias => {
        setSelectedMetric(alias);
        refreshFormula(selectedTicker, alias, selectedPeriod, selectedYear);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 1000);
        return formula;
    }

    const handleTickerChange = e => {
        let ticker = e.target.value;
        if (e.target.value.length == 0) {
            setSelectedTicker('APPL');
        } else {
            setSelectedTicker(e.target.value);
            refreshFormula(ticker, selectedMetric, selectedPeriod, selectedYear);
        }
    };

    const handleNoPropagation = e => {
        e.stopPropagation();
    }

    const refreshFormula = (symbol, alias, period, year) => {
        let formula = ""
        if (period != "Period" && year == 'Year') {
            formula = "=mkt(\"" + symbol + "\", \"" + alias + "\", \"" + period + "-" + "20\")";
        }
        else if (period == 'Period' && year != "Year") {
            formula = "=mkt(\"" + symbol + "\", \"" + alias + "\", \"ytd-" + year.slice(2) + "\")";
        }
        else if (period != 'Period' && year != "Year") {
            formula = "=mkt(\"" + symbol + "\", \"" + alias + "\", \"" + period + "-" + year.slice(2) + "\")";
        }
        else {
            formula = "=mkt(\"" + symbol + "\", \"" + alias + "\")";
        }
        setFormula(formula);
        return formula;
    };

    const handleCopy = alias => {
        setSelectedMetric(alias);
        let formulaUpdated = refreshFormula(selectedTicker, alias, selectedPeriod, selectedYear);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 1000);
        let dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.setAttribute('value', formulaUpdated);
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    };

    const reload = e => {
        setMetrics(JSON.parse(localStorage.getItem('metrics')));
        setMetricListView(true);
        setMetricView(false);
    };

    const handleClick = e => {
        setLoading(true);
        setSearch("");
        let alias = e.currentTarget.getAttribute("data-index");
        setSelectedMetric(alias);
        metricService.getByAlias(alias)
            .then(res => {
                setMetric(res);
                refreshFormula(selectedTicker, alias, selectedPeriod, selectedYear)
                setLoading(false);
            });
        setMetricView(true);
        setMetricListView(false);
    };

    const handleClickYear = e => {
        toggleYearDropdown(!yearDropdown)
    }

    const handleClickPeriod = e => {
        togglePeriodDropdown(!periodDropdown)
    }

    const handleYearSelect = e => {
        let year = e.currentTarget.getAttribute("data-index");
        toggleYearDropdown(false);

        if ((year == "2020" && selectedPeriod == "Period") || selectedPeriod == "ytd") {
            setSelectedYear("2020");
            setSelectedPeriod("ytd");
            refreshFormula(selectedTicker, selectedMetric, "ytd", "2020");
        } else if (selectedPeriod == "Period") {
            setSelectedYear(year);
            setSelectedPeriod("y");
            refreshFormula(selectedTicker, selectedMetric, "y", year);
        } else {
            setSelectedYear(year);
            refreshFormula(selectedTicker, selectedMetric, selectedPeriod, year);
        }
    }

    const handlePeriodSelect = e => {
        let period = e.currentTarget.getAttribute("data-index");
        togglePeriodDropdown(false);

        if (selectedYear == "Year" || period == "ytd") {
            setSelectedYear("2020");
            setSelectedPeriod(period);
            refreshFormula(selectedTicker, selectedMetric, period, "2020");
        } else if (selectedYear == "2020" && (period != 'ytd' || period != 'y')) {
            if (currentAvailableQuarters.indexOf(period) == -1) {
                period = currentAvailableQuarters.slice(-1)[0];
            }
            setSelectedPeriod(period);
            refreshFormula(selectedTicker, selectedMetric, period, "2020");
        } else {
            setSelectedPeriod(period);
            refreshFormula(selectedTicker, selectedMetric, period, selectedYear);
        }
    }

    const handleScroll = e => {
        setLoading(true);
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            setPreLoad(preLoad + 5);
        }
        setLoading(false);
    };

    const verticalAlignCenter = {
        marginTop: "3px",
        marginRight: "5px"
    }

    const buttonHeight = {
        height: "37px"
    }

    const active = 'bg-gray-100 font-bold';

    library.add({ faCopy, faChevronLeft, faTimes });
    dom.watch();

    return (
        <div className="w-full max-w-screen-xl mx-auto ">
            <div className="flex justify-center p-2 px-3 pb-10 pt-0">
                <div className="w-full">
                    <div className="bg-white shadow-md rounded-lg px-3 py-2 mb-4 order-solid border-2 border-gray-200">
                        <div className="flex items-center bg-gray-200 rounded-md mt-2">
                            <div className="pl-2">
                                <svg className="fill-current text-gray-500 w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path className="heroicon-ui" d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                                </svg>
                            </div>

                            <input onChange={handleChange} value={search} className="w-full rounded-md bg-gray-200 text-gray-700 leading-tight focus:outline-none py-2 px-2" id="search" type="text" placeholder="Ex: net income..." />
                            {/* <button onClick={handleSearch} class="bg-white focus:outline-none hover:bg-gray-100 text-gray-800 py-2 px-4 border border-gray-400 rounded">
                                Search
                            </button> */}
                        </div>
                        <div className="mb-2 mt-2 text-sm h-auto">
                            {loading &&
                                <div className="mb-24">
                                    <div className="mx-auto mt-12 loader-darker rounded-full border-2 border-t-2 border-transparent h-24 w-24" />
                                </div>

                            }
                            {!loading && metricListView &&
                                <ul onScroll={handleScroll} className="overflow-auto h-64">
                                    {Object.keys(metrics).slice(0, preLoad).map((alias, index) =>
                                        <li onClick={handleClick} data-index={alias} key={alias} >
                                            <div className="flex justify-start focus:outline-none cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
                                                {/* <span className="bg-gray-400 h-2 w-2 m-2 rounded-full" /> */}
                                                <div className="flex-grow font-medium px-2">{metrics[alias]}</div>
                                                <div className="text-lg font-normal text-gray-500 tracking-wide">
                                                    {copySuccess && selectedMetric == alias && <span className="text-sm mr-1">Copied! </span>}
                                                    <CopyToClipboard onCopy={() => handleCopy(alias)}>
                                                        <span onClick={handleNoPropagation}>
                                                            <i className="far fa-copy mr-2 hover:text-primary"></i>
                                                        </span>
                                                    </CopyToClipboard>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            }
                            {!loading && metricView &&
                                <div className="bg-gray-100 w-full h-full">
                                    <div className="flex md:flex-row flex-wrap">
                                        <div className="inline w-full sm:hidden text-gray-700 text-right">
                                            <div>
                                                <button onClick={reload} className="bg-transparent hover:text-black rounded text-right p-1 text-gray-400 hover:text-2xl">
                                                    <i className="fas fa-times text-xs sm:text-xl"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="w-full sm:w-3/4 text-left pl-3 pb-4 sm:py-4 text-gray-700">
                                            <div className="text-sm sm:text-2xl sm:ml-2 font-bold">
                                                {metric['full_name']}
                                            </div>
                                        </div>
                                        <div className="hidden sm:inline sm:w-1/4 text-gray-700 text-right">
                                            <div>
                                                <button onClick={reload} className="bg-transparent hover:text-black rounded text-right p-1 text-gray-400 hover:text-2xl">
                                                    <i className="fas fa-times text-xs sm:text-xl"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:ml-4 mb-4 sm:mb-6">
                                        <div className="hidden sm:inline">
                                            <span className="bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">Metric ID: {} <span className="font-bold text-primary">{metric['alias']}</span></span>
                                            <span className="bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 ml-4">Unit: {} <span className="font-bold text-primary">{metric['unit']}</span></span>
                                        </div>

                                        <div className="sm:hidden mx-2">
                                            <div className="flex bg-gray-200 rounded-full px-2 py-1 text-sm font-semibold text-gray-700">
                                                Metric ID:  {} <span className="font-bold pl-1 text-primary">{metric['alias']}</span>
                                            </div>
                                            <div className="flex mt-1 bg-gray-200 rounded-full mt-2 px-2 py-1 text-sm font-semibold text-gray-700">
                                                Unit:  {} <span className="font-bold pl-1 text-primary">{metric['unit']}</span>
                                            </div>
                                        </div>


                                    </div>

                                    {metric['description'] == "" ? null :
                                        <div className="mx-4 sm:ml-6 flex text-gray-700 md:flex-row flex-wrap">

                                            <div className="text-xs sm:w-1/6 hidden sm:inline sm:text-lg sm:font-bold">Description</div>

                                            <div className="text-xs sm:pl-3 h-24 sm:pt-1 sm:h-auto sm:w-5/6 sm:pr-4 text-gray-600 overflow-auto">
                                                {metric['description']}
                                            </div>
                                        </div>
                                    }

                                    <div className="sm:ml-6 flex mt-3 text-gray-700 md:flex-row flex-wrap">

                                        <div className="hidden sm:inline text-xs w-1/6 sm:text-lg sm:font-bold">Formula</div>

                                        <div className="text-xs w-full pl-2 sm:h-10 mt-2 mb-2 sm:w-5/6 pr-2 sm:pr-4 text-gray-600">
                                            <div className="flex rounded overflow-hidden">
                                                <div className="hidden sm:inline border-2 border-gray-300 font-bold font-italic text-lg rounded-l px-2 shadow-border p-1">
                                                    <i>fx</i>
                                                </div>
                                                {copySuccess ? <div className="hidden sm:inline block bg-primary py-2 w-full text-white font-bold text-sm text-center">Copied!</div> :
                                                    <div className="hidden sm:inline block w-full text-gray-700 text-xs sm:text-sm shadow-border bg-gray-300 py-2 sm:px-2 pl-1 font-sans tracking-wide ">
                                                        {/* {} = mkt("{ticker}", <b>"{selectedMetric['alias']}"</b>) */}
                                                        {formula}
                                                    </div>
                                                }
                                                {/* text={"=mkt(\"" + ticker + "\", \"" + selectedMetric['alias'] + "\")"}  */}
                                                <CopyToClipboard text={formula} onCopy={() => handleCopy(metric['alias'])}>
                                                    <button className="block w-full sm:w-auto focus:outline-none bg-primary hover:bg-primary-darker text-white rounded-r shadow-border p-1 sm:px-2">
                                                        <div className="text-xs sm:hidden">
                                                            {copySuccess ? <span>Copied!</span> : <span>Copy Formula</span>}
                                                            <i className="far fa-copy ml-2 text-sm sm:hidden"></i>
                                                        </div>
                                                        <i className="hidden sm:inline far fa-copy text-xs sm:text-lg"></i>
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="flex items-center block bg-gray-200 text-sm py-4 -mx-3 -mb-2 rounded-b-lg">
                            <div className="ml-4 font-bold">
                                <input onChange={handleTickerChange} style={buttonHeight} className="appearance-none text-center w-16 rounded px-2 font-light leading-tight focus:outline-none focus:shadow-outline" id="ticker" type="text" placeholder="AAPL" />
                            </div>

                            <div className="ml-3">
                                <div className="dropdown inline-block relative">
                                    <button onClick={handleClickPeriod} className="bg-white w-20 hover:bg-gray-100 focus:outline-none font-light py-2 pl-2 pr-1 rounded inline-flex items-center">
                                        <div className="flex" >
                                            <div className="w-full">
                                                {selectedPeriod}
                                            </div>
                                            <div style={verticalAlignCenter} className="absolute right-0">
                                                <svg className="fill-current h-4 w-4 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /> </svg>
                                            </div>
                                        </div>


                                    </button>
                                </div>
                                <ul className={periodDropdown ? "absolute cursor-pointer font-light mt-1 w-20" : "hidden"}>
                                    <li ><div data-index="ytd" onClick={handlePeriodSelect} className="rounded-t bg-white hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="#">ytd</div></li>
                                    <li ><div data-index="y" onClick={handlePeriodSelect} className="bg-white hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="#">y</div></li>
                                    <li ><div data-index="q1" onClick={handlePeriodSelect} className="bg-white hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="#">q1</div></li>
                                    <li ><div data-index="q2" onClick={handlePeriodSelect} className="bg-white hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="#">q2</div></li>
                                    <li ><div data-index="q3" onClick={handlePeriodSelect} className="bg-white hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="#">q3</div></li>
                                    <li ><div data-index="q4" onClick={handlePeriodSelect} className="rounded-b bg-white hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="#">q4</div></li>
                                </ul>
                            </div>
                            <div className="ml-3">
                                <div className="dropdown inline-block relative">
                                    <button onClick={handleClickYear} className="bg-white w-16 hover:bg-gray-100 focus:outline-none font-light py-2 pl-2 rounded inline-flex items-center">
                                        <span className="mr-1">{selectedYear}</span>
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /> </svg>
                                    </button>
                                </div>
                                <ul className={yearDropdown ? "absolute cursor-pointer overflow-auto h-48 font-light mt-1 w-16" : "hidden"}>
                                    {historicalYears.map(year =>
                                        <li ><a key={year}
                                            onClick={handleYearSelect}
                                            data-index={year}
                                            className="bg-white hover:bg-gray-400 py-2 pl-2 pr-4 block whitespace-no-wrap" >
                                            {year}
                                        </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export { SearchMetric };